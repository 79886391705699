import React, { useEffect, useState } from 'react';

import {
	ChangePassword,
	DeleteAccount,
	Email,
	LogoutSetting,
	NotifySetting,
	Phone,
	Reminder,
	Card,
} from 'assets/images/icons';
import { disabledInspect, emailRegex, getTimezoneOffset } from 'utils/index';
import { Service } from 'config/service';

import { Box, Breadcrumbs, FormControl, Grid, MenuItem, Select, Switch, Typography } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// *Import Components
import Deleted from 'components/delete';
import ModalPayment from './ModalPayment/Index';
import { useStore } from '../../context/AppContext';
import moment from 'moment';

function Settings() {
	const [notify, setNotify] = useState(false);
	const { authStore, paymentStore } = useStore();

	const [phone, setPhone] = useState();
	const [countryCode, setCountryCode] = useState();

	// Payment
	const [openModal, setOpenModal] = useState(false);
	const [paymentEnable, setPaymentEnable] = useState(false);
	const [paymentInfo, setPaymentInfo] = useState(null);

	// *For Loader
	const [loader, setLoader] = useState(false);

	// *For Form Validation
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		setValue,
	} = useForm();

	// *For onChange Email Validation
	const email = register('email', {
		required: 'Email is required',
		pattern: {
			value: emailRegex,
			message: 'Please enter a valid email address',
		},
	});

	// *For Delete Account
	const [openDeleteAccount, setOpenDeleteAccount] = useState(false);

	// *For Save Button Hide
	const [button, setButton] = useState(false);

	// *Get User Data
	const getUserData = () => {
		try {
			const userData = authStore.user;
			setPhone(userData?.phone_number);
			setCountryCode(userData?.countryCode);
			setNotify(userData?.appSettings?.isNotifyEnable);

			// *For Default Value
			setValue('email', userData?.email);
			setValue('phoneInput', userData?.phone_number);
			setValue('notification', userData?.appSettings?.isNotifyEnable);
		} catch (error) {
			console.log('file: settings.js => line 22 => getUserData => error', error);
		}
	};

	// *Get Time Interval
	

	// *For Update Setting
	const updateSetting = async (data) => {
		if (authStore.user && authStore.isExpiredSubscription()) {
			paymentStore.onShowMessageDialog();
			return;
		}
		setLoader(true);
		try {
			const token = localStorage.getItem('jwt');
			let obj = {};
			if (data.password) {
				obj = {
					email: data.email,
					password: data.password,
					countryCode,
					phoneNumber: control._formValues.phoneInput,
					dailyOpenTime: data.openTime || 0,
					dailyTimeInterval: data.timeInterval || 0,
					isNotifyEnable: data.notification,
					timezoneOffset: getTimezoneOffset(),
				};
			} else {
				obj = {
					email: data.email,
					countryCode,
					phoneNumber: control._formValues.phoneInput,
					dailyOpenTime: data.openTime || 0,
					dailyTimeInterval: data.timeInterval || 0,
					isNotifyEnable: data.notification,
					timezoneOffset: getTimezoneOffset(),
				};
			}
			const { message } = await Service.updateSetting(obj, token);
			toast.success(message, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				progress: undefined,
			});
			await authStore.loadUserProfile();
		} catch (error) {
			toast.error(error, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',

				progress: undefined,
			});
		} finally {
			setLoader(false);
		}
	};

	// *For Save Button Hide & Show
	const saveButton = () => {
		try {
			setButton(true);
		} catch (error) {
			console.log('file: settings.js => line 127 => setSaveButton => error', error);
		}
	};

	// *For Delete Account Open and Close Dialog
	const deleteAccountDialog = (type) => {
		if (authStore.user && authStore.isExpiredSubscription()) {
			paymentStore.onShowMessageDialog();
		} else {
			if (type === true) {
				setOpenDeleteAccount(true);
			} else {
				setOpenDeleteAccount(false);
			}
		}
	};

	// *For Delete Account
	const deleteAccount = async () => {
		try {
			const token = localStorage.getItem('jwt');
			const obj = {};
			const { message } = await Service.deleteAccount(obj, token);
			toast.success(message, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				progress: undefined,
			});
			deleteAccountDialog(false);
			authStore.logout();
		} catch (error) {
			toast.error(error, {
				position: 'top-center',
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				theme: 'colored',

				progress: undefined,
			});
		}
	};

	const getCurrentPaymentMethod = async () => {
		try {
			const token = localStorage.getItem('jwt');
			const { data } = await Service.getPaymentMethod(token);
			if (data && data.type) {
				setPaymentEnable(true);
				setPaymentInfo({
					type: data?.type || '',
					last4: data?.card?.last4 || '',
					brand: data?.card?.brand || '',
					display_brand: data?.card?.display_brand || '',
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getSubscription = () => {
		const subscription = authStore?.user?.subscription;
		const { status, trial_end, ended_at } = subscription;
		if (status === 'trialing') {
			const diffTrial = moment(trial_end).diff(moment(), 'days');
			if (diffTrial < 0) {
				return 'Your Free Trial has expired.';
			}
			return `${diffTrial} Days Trial Left.`;
		} else if (status === 'past_due' || status === 'canceled') {
			return 'Your Free Trial has expired.';
		}
		const diffPlan = moment(ended_at).diff(moment(), 'days');
		if (diffPlan < 0) {
			return 'Your Free Trial has expired.';
		}
		return `${diffPlan} Days Left Until Next Payment.`;
	};

	useEffect(() => {
		getUserData();
	}, [authStore.user]);

	useEffect(() => {
		disabledInspect();
		getCurrentPaymentMethod().then();
		window.scrollTo({ top: 0 });
	}, []);

	return (
		<>
			{/* ========== Delete Account Dialog ========== */}
			<Deleted
				open={openDeleteAccount}
				onClose={() => {
					deleteAccountDialog(false);
				}}
				deleted={deleteAccount}
			/>

			{/* ========== Settings ========== */}
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					{/* ========== Breadcrumbs ========== */}
					<Breadcrumbs aria-label="breadcrumb">
						<Typography>Settings</Typography>
					</Breadcrumbs>
					{authStore?.user && authStore?.user?.subscription && (
						<Typography
							style={{ color: '#E82D2D', fontSize: '16px', fontWeight: 700, fontFamily: 'Avenir' }}
						>
							{getSubscription()}
						</Typography>
					)}
				</Box>

				<form onSubmit={handleSubmit(updateSetting)}>
					<Grid className="setting" container spacing={0} justifyContent="center" alignItems="stretch">
						{/* ========== Account ========== */}
						<Grid className="account" container spacing={0} item sm={12} md={12} alignItems="flex-start">
							<Grid item md={12}>
								<Typography className="heading">Account</Typography>
							</Grid>
							<Grid container spacing={1} item sm={12} md={4} alignItems="flex-end">
								<div className="input-field">
									<div className="icon">
										<Email />
									</div>
									<div className="input-text">
										<label>Email</label>
										<input
											type="text"
											{...register('email', {
												required: 'Email is required',
												pattern: {
													value: emailRegex,
													message: 'Please enter a valid email address',
												},
											})}
											onChange={(e) => {
												email.onChange(e);
												saveButton(e);
											}}
										/>
									</div>
								</div>
								{errors?.email?.message && <p className="error">{errors?.email?.message}</p>}
							</Grid>
							<Grid container spacing={1} item sm={12} md={4} alignItems="flex-end">
								<div className="input-field">
									<div className="icon">
										<ChangePassword />
									</div>
									<div className="input-text">
										<label>Password</label>
										<input
											type="password"
											placeholder="*********"
											{...register('password', {
												minLength: {
													value: 8,
													message: 'Password must have at least 8 characters',
												},
											})}
											onChange={(e) => {
												e.preventDefault();
												saveButton();
											}}
										/>
									</div>
								</div>
							</Grid>
							<Grid container spacing={1} item sm={12} md={4} alignItems="flex-end">
								<div className="input-field">
									<div className="icon">
										<Phone />
									</div>
									<div className="input-text" style={{ marginTop: '-5px' }}>
										<label style={{ marginBottom: '5px' }}>Phone</label>
										<Controller
											name="phoneInput"
											control={control}
											rules={{
												required: 'Phone Number is required',
												validate: (value) =>
													isValidPhoneNumber(value) || 'Invalid phone number',
											}}
											render={({ field: { onChange } }) => (
												<PhoneInput
													disabled
													value={phone}
													onChange={onChange}
													defaultCountry={countryCode}
													country={countryCode}
													onCountryChange={(c) => setCountryCode(c)}
													id="phoneInput"
												/>
											)}
										/>
									</div>
								</div>
								{errors?.phoneInput?.message && <p className="error">{errors?.phoneInput?.message}</p>}
							</Grid>
						</Grid>
						{/* ========== Payment ========== */}
						<Grid
							className="app-setting"
							container
							spacing={0}
							item
							sm={12}
							md={12}
							alignItems="flex-start"
						>
							<Grid container spacing={1} item sm={12} md={4} alignItems="flex-end">
								<div className="input-field input-field-change-payment">
									<div className="icon">
										<Card />
									</div>
									<div className="input-text">
										<label>Payment Method</label>
										<div className="payment-info-wrapper">
											{paymentEnable ? (
												<div className="payment-info-method-current">
													<div className="payment-info-method-brand">
														{paymentInfo?.display_brand || ''}&nbsp;
													</div>
													{`ending in ${paymentInfo?.last4 || ''}`}
												</div>
											) : (
												<div />
											)}
											<button
												className="payment-info-btn"
												type="button"
												onClick={() => {
													if (authStore.user && authStore.isExpiredSubscription()) {
														paymentStore.onShowMessageDialog();
														return;
													}
													setOpenModal(true);
												}}
											>
												{paymentEnable ? 'Change' : 'Add'}
											</button>
										</div>
									</div>
								</div>
							</Grid>
							{/* <Grid container spacing={1} item sm={12} md={4} alignItems="flex-end">
                  <div className="input-field">
                    <div className="icon">
                      <ChangePassword />
                    </div>
                    <div className="input-text">
                      <label>Password</label>
                      <input
                        type="password"
                        placeholder="*********"
                        {...register("password", {
                          minLength: {
                            value: 8,
                            message: "Password must have at least 8 characters"
                          }
                        })}
                        onChange={(e) => { saveButton() }}
                      />
                    </div>
                  </div>
                </Grid> */}
						</Grid>
						{/* ========== App Settings ========== */}
						<Grid
							className="app-setting"
							container
							spacing={0}
							item
							sm={12}
							md={12}
							alignItems="flex-start"
						>
							<Grid item md={12}>
								<Typography className="heading">App Settings</Typography>
							</Grid>
							
							
							<Grid container spacing={1} item md={4} alignItems="flex-end">
								<div className="input-field">
									<div className="icon align-icon">
										<NotifySetting />
									</div>
									<div className="input-text">
										<Typography component="p">
											Notification
											<Switch
												checked={notify}
												value={notify}
												{...register('notification')}
												onChange={() => {
													setNotify((prev) => !prev);
													saveButton();
												}}
											/>
										</Typography>
									</div>
								</div>
							</Grid>
						</Grid>

						{/* ========== User Decision ========== */}
						<Grid
							className="user-decision"
							container
							spacing={0}
							item
							sm={12}
							md={12}
							alignItems="flex-start"
						>
							<Grid item md={12}>
								<Typography className="heading">User Decision</Typography>
							</Grid>
							<Grid container spacing={1} item md={4} alignItems="flex-end">
								<div className="input-field">
									<div className="icon align-icon">
										<DeleteAccount />
									</div>
									<div className="input-text">
										<Typography
											className="cursor-pointer"
											component="p"
											onClick={() => deleteAccountDialog(true)}
										>
											Delete My Account
										</Typography>
									</div>
								</div>
							</Grid>
							<Grid container spacing={1} item md={4} alignItems="flex-end">
								<div className="input-field">
									<div className="icon align-icon">
										<LogoutSetting />
									</div>
									<div className="input-text">
										<Typography
											className="cursor-pointer"
											component="p"
											onClick={() => authStore.logout()}
										>
											Logout
										</Typography>
									</div>
								</div>
							</Grid>
						</Grid>

						{/* ========== Save ========== */}
						<Grid item xs={12} sm={12} md={3}>
							{button && (
								<button
									type="submit"
									className={`button-raised ${loader === true ? 'spinner button-disabled ' : ''}`}
									disabled={loader === true}
								>
									Save
								</button>
							)}
						</Grid>
					</Grid>
				</form>
			</Grid>

			{/* ========== Payment Popup ========== */}
			<ModalPayment
				open={openModal}
				onClose={() => setOpenModal(false)}
				paymentEnable={paymentEnable}
				paymentInfo={paymentInfo}
			/>
		</>
	);
}

export default Settings;
